
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      sid: "",
      token: "",
      publishable_key: "",
      secret_key: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    // const value = ref('');
    const registration = Yup.object().shape({
      sid: Yup.string().required().label("Sid"),
      token: Yup.string().required().label("Token"),
      publishable_key: Yup.string().required().label("Publishable key"),
      secret_key: Yup.string().required().label("Secret key"),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Account Setting", []);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        getUser();
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
    });
    const getUser = () => {
      var request = {
        url: `setting`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data);
          formData.value = {
            sid: data.data.sid,
            token: data.data.token,
            publishable_key: data.data.stripe_publishable_key,
            secret_key: data.data.stripe_secret_key,
          };
        }
      });
    };

    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // console.log('values',values);
      loading.value = true;
      var request = {
        url: "settings",
        data: {
          sid: values.sid,
          token: values.token,
          stripe_publishable_key: values.publishable_key,
          stripe_secret_key: values.secret_key,
        },
      };
      store
        .dispatch(Actions.POST, request)
        .then((data) => {
          if (data) {
            Swal.fire("Success", "Setting updated successfully!", "success");
            router.push({ name: "dashbord" });
          }
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      //}, 2000);
      // Swal.fire({
      //   text: "Setting added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "dashboard" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      formData,
    };
  },
});
